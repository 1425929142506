import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthState } from '@core/store/auth/auth.state';
import { SharedState } from '@core/store/shared/shared.state';
import { environment } from '@env/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NotificationState } from '@shared/components/notification-drawer/state/notification.state';
import { SharedModule } from '@shared/shared.module';

import { WorkflowState } from 'app/json-schema-forms/store/workflow.state';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CookieService } from 'ngx-cookie-service';
import { HttpInterceptorService } from './services/auth/http-interceptor.service';
import { AppState } from './store/app/app.state';
import { JobUpdatesState } from './store/job-updates/job-updates.state';

@NgModule({
  declarations: [],
  imports: [
    // here import the modules that we need to import once in the application
    HttpClientModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([AuthState, SharedState, WorkflowState, NotificationState, AppState, JobUpdatesState], {
      developmentMode: !environment.production,
    } as unknown),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: 'auth',
    }),
    SharedModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    CookieService,
  ],
  exports: [],
})
export class CoreModule {}
