<div class="forms-generator-container">
  <!-- <mat-form-field>
    <mat-label>Language</mat-label>
    <mat-select [(value)]="selectedLanguage">
      <mat-option></mat-option>
      @for (option of languages; track option) {
        <mat-option [value]="option.value">{{ option.viewValue }}</mat-option>
      }
    </mat-select>
  </mat-form-field> -->
  <form [formGroup]="form" (ngSubmit)="submit()">
    <formly-form [form]="form" [model]="model" [fields]="fields" [options]="options"> </formly-form>
    <!-- <button type="submit" color="primary" mat-raised-button>Submit</button>

    <button type="reset" color="warn" mat-raised-button>Reset</button> -->
  </form>
</div>
