import { Injectable } from '@angular/core';
import { EntityUpdateNotification } from '@core/models/entity-update-notification.model';
import { FirebaseService } from '@core/services/firebase/firebase.service';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Timestamp } from 'firebase/firestore';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { EntityUpdateStateModel } from './entity-updates-state.model';
import {
  ClearEntityUpdates,
  ListenForEntityUpdates,
  StopListeningForEntityUpdates,
  UpdateEntityUpdates,
} from './entity-updates.actions';

const defaults: EntityUpdateStateModel = {
  entityId: null,
  entityUpdates: [],
};

export const ENTITY_UPDATES_STATE_TOKEN = new StateToken<EntityUpdateStateModel>('entityUpdates');

@State<EntityUpdateStateModel>({
  name: ENTITY_UPDATES_STATE_TOKEN,
  defaults,
})
@Injectable()
export class EntityUpdatesState {
  private entityUpdatesUnSubscription = new Subject<void>();
  constructor(
    private store: Store,
    private readonly firebaseService: FirebaseService
  ) {}

  @Selector()
  static getEntityUpdates(state: EntityUpdateStateModel) {
    return state.entityUpdates;
  }

  @Selector()
  static getYouAreHereUpdates(state: EntityUpdateStateModel) {
    return state.entityUpdates
      .filter(update => update.data['entityType'] === 'YouAreHere')
      .sort((a, b) => {
        return a.timestamp - b.timestamp;
      })[0];
  }

  @Selector()
  static getLatestEntityUpdates(state: EntityUpdateStateModel) {
    return state.entityUpdates.length > 0 ? state.entityUpdates[state.entityUpdates.length - 1] : null;
  }

  @Action(UpdateEntityUpdates)
  updateEntityUpdates(ctx: StateContext<EntityUpdateStateModel>, { entityUpdates }: UpdateEntityUpdates) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const existingEntityUpdatesSize = ctx.getState().entityUpdates.length;
    const newEntityUpdatesSize = entityUpdates.length;

    if (newEntityUpdatesSize >= 4) {
      ctx.patchState({ entityUpdates });
    } else {
      ctx.patchState({
        entityUpdates: [
          ...ctx.getState().entityUpdates.slice(newEntityUpdatesSize - existingEntityUpdatesSize),
          ...entityUpdates,
        ],
      });
    }
  }

  @Action(ClearEntityUpdates)
  clearEntityUpdates(ctx: StateContext<EntityUpdateStateModel>) {
    ctx.patchState(defaults);
  }

  @Action(ListenForEntityUpdates)
  listenForEntityUpdates(ctx: StateContext<EntityUpdateStateModel>, { entityId, orgId }: ListenForEntityUpdates) {
    const { entityId: currentEntityId } = ctx.getState();

    const {
      user: { orgId: currentOrgId },
    } = this.store.selectSnapshot(s => s.auth);

    console.log('listenForEntityUpdates ', orgId ?? currentOrgId, entityId);

    if (currentEntityId !== entityId) {
      if (currentEntityId) {
        this.entityUpdatesUnSubscription.next();
        this.entityUpdatesUnSubscription.complete();
      }
      ctx.patchState({ entityId });
    } else {
      console.log('entityId is the same as the current entityId, returning');
      return;
    }

    this.entityUpdatesUnSubscription = new Subject<void>();
    console.log('orgId: ', orgId ?? currentOrgId);
    console.log('entityId: ', entityId);
    this.firebaseService
      .getEntityUpdates(orgId ?? currentOrgId, entityId)
      .pipe(
        takeUntil(this.entityUpdatesUnSubscription),
        tap(updates => {
          const entityUpdates: EntityUpdateNotification[] = updates.map(update => {
            const data = update.payload.doc.data();
            const obj = {
              entityId: data['entityId'],
              data,
              entityType: data['entityType'],
              operation: data['operation'],
              orgId: data['orgId'],
              timestamp: (data['timestamp'] as Timestamp).toMillis(),
            };
            return obj;
          });
          if (entityUpdates.length > 0) {
            this.store.dispatch(new UpdateEntityUpdates(entityUpdates));
          }
        }),
        catchError(error => {
          console.error('Error listening for job updates', error);
          return of(null);
        })
      )
      .subscribe();
  }

  @Action(StopListeningForEntityUpdates)
  stopListeningForEntityUpdates(ctx: StateContext<EntityUpdateStateModel>) {
    this.entityUpdatesUnSubscription.next();
    this.entityUpdatesUnSubscription.complete();
    console.log('stopListeningForEntityUpdates');
  }
}
