import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '@core/models/user.model';
import { AuthState } from '@core/store/auth/auth.state';
import { Select, Store } from '@ngxs/store';
import { EntityMap } from '@shared/types/entity-map.type';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  currentUser: User;
  routingMap: EntityMap<string, string[]>;
  @Select(AuthState.getUser) user$: Observable<User>;

  constructor(
    private router: Router,
    public store: Store
  ) {
    this.user$.pipe(tap(user => (this.currentUser = user))).subscribe();
  }

  // TODO: handle authorization part
  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    return true;
  }

  // TODO: handle authorization part
  canActivateChild(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    return true;
  }

  isAuthenticated(): boolean {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }

  // TODO: handle authorization part when available
  checkLogin(): boolean {
    if (this.isAuthenticated()) {
      return true;
    }
    // this.router.navigate(['/login', ]);
    return true;
  }

  private getToken(): string {
    return this.store.selectSnapshot(AuthState.token);
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64));
    return JSON.parse(jsonPayload);
  }
}
