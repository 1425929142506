<div class="chip-container" [ngClass]="props.orientation">
  <div *ngFor="let item of items">
    <div class="chip" [ngStyle]="styleObject">
      <div class="icon" *ngIf="props?.icon">
        {{ item?.name?.charAt(0)?.toUpperCase() }}
      </div>
      <div class="text">{{ item?.name }}</div>
      <button
        class="button icon"
        (click)="remove(item)"
        (keydown.enter)="remove(item)"
        *ngIf="props?.action"
        tabindex="0"
        aria-label="Remove item">
        <mat-icon class="close-btn">close</mat-icon>
      </button>
    </div>
  </div>
</div>
