<mat-form-field [className]="props.style" [appearance]="props.appearance">
  <mat-label id="label" [ngClass]="getStylesClasses('label')"
    >{{ props?.label }} {{ props.required ? '*' : '' }}</mat-label
  >
  <input
    id="input"
    [ngClass]="getStylesClasses('input')"
    matInput
    [matAutocomplete]="auto"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [placeholder]="props.placeholder"
    [errorStateMatcher]="errorStateMatcher" />
  <div matSuffix style="display: flex">
    <mat-icon>{{ props.icon }}</mat-icon>
  </div>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let item of filter" [value]="item">
      {{ item.label }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="formControl.errors?.required">{{ field.validation?.messages?.required }}</mat-error>
</mat-form-field>
