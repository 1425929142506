import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule, FieldType } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

@Component({
  selector: 'app-null-type',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormlyMaterialModule, FormlyModule, HttpClientModule],
  templateUrl: './null-type.component.html',
  styleUrl: './null-type.component.scss'
})
export class NullTypeComponent extends FieldType {

}
