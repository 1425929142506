import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';

@Component({
  selector: 'app-label-type',
  standalone: true,
  imports: [CommonModule, MatTabsModule, FormlyMaterialModule, FormlyModule],
  templateUrl: './label-type.component.html',
  styleUrl: './label-type.component.scss',
})
export class LabelTypeComponent extends FieldType {
  constructor(private readonly jsonSchemaService: JsonSchemaService) {
    super();
  }

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject(this.props['styles'] || '');
  }
}
