import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, inject, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType, FormlyMaterialModule } from '@ngx-formly/material';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';

interface AutocompleteOption {
  label: string;
  value: string | number;
  isCustomValue?: boolean;
  [key: string]: unknown;
}

interface GqlQueryFields {
  label: string;
  value: string;
}

interface GqlQueryConfig {
  query: string;
  fields: GqlQueryFields;
  variables: Record<string, unknown>;
  searchable?: boolean;
  queryInput?: string;
  singleQuery?: {
    query: string;
    variables: Record<string, unknown>;
  };
}

interface AutocompleteChipProps {
  gqlQuery?: GqlQueryConfig;
  url?: string;
  options?: AutocompleteOption[];
  valuePropertyName?: string;
  allowCustomValues?: boolean;
  styleClasses?: Record<string, string>;
  styles?: string;
  debounceTime?: number;
  minLengthForDebounce?: number;
}

@Component({
  selector: 'app-autocomplete-chip',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatInputModule,
    FormlyMaterialModule,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatChipsModule,
    FormsModule,
  ],
  templateUrl: './autocomplete-chip.component.html',
  styleUrl: './autocomplete-chip.component.scss',
})
export class AutocompleteChipComponent extends FieldType<FormlyFieldConfig> implements OnInit, OnDestroy {
  private $destroy = new Subject<void>();
  filter: Observable<AutocompleteOption[]> | undefined;
  filterControl = new FormControl('');
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly values = signal<AutocompleteOption[]>([]);
  readonly announcer = inject(LiveAnnouncer);
  customValue: string = '';
  optionsData: AutocompleteOption[] = [];
  filteredOptionsWithCustom: AutocompleteOption[] = [];
  isFiltering = false;
  @ViewChild('chipInput') chipInput?: ElementRef<HTMLInputElement>;

  constructor(
    private jsonSchemaService: JsonSchemaService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.values.set([]);
    this.initialDataLoad();
    this.setupFilteringOnInput();
  }

  /**
   * Returns the debounce time in milliseconds.
   * This can be configured through the 'debounceTime' property.
   * Defaults to 300ms if not specified.
   */
  get debounceTimeMs(): number {
    const configuredDebounceTime = this.props?.['debounceTime'] as number | undefined;
    return configuredDebounceTime && configuredDebounceTime > 0 ? configuredDebounceTime : 300;
  }

  /**
   * Returns the minimum length required to apply debounce.
   * This can be configured through the 'minLengthForDebounce' property.
   * Defaults to 0 (always apply debounce).
   */
  get minLengthForDebounce(): number {
    const minLength = this.props?.['minLengthForDebounce'] as number | undefined;
    return minLength !== undefined ? minLength : 0;
  }

  /**
   * Indicates whether custom values are allowed
   */
  get allowCustomValues(): boolean {
    return !!this.props?.['allowCustomValues'];
  }

  initialDataLoad(): void {
    if (this.formControl?.value) {
      // Handle existing values for edit mode
      const gqlQuery = this.props?.['gqlQuery'] as GqlQueryConfig | undefined;
      if (gqlQuery && gqlQuery.query && gqlQuery.fields && gqlQuery.fields.label && gqlQuery.fields.value) {
        if (gqlQuery.searchable) {
          if (gqlQuery.queryInput) {
            const variables = gqlQuery.variables[gqlQuery.queryInput] as Record<string, unknown>;
            if (variables) {
              variables['searchKey'] = '';
            }
          } else {
            gqlQuery.variables['searchKey'] = '';
          }
        }
        this.loadDataWithGql()
          .pipe(take(1))
          .subscribe((data: AutocompleteOption[]) => {
            const defaultValues = Array.isArray(this.formControl.value)
              ? this.formControl.value
              : [this.formControl.value];

            const matchingValues = data.filter(item => defaultValues.includes(item?.value));
            this.values.set(matchingValues);
            this.optionsData = data;
            this.updateFilteredOptions();

            // Handle single query for missing values
            if (matchingValues.length < defaultValues.length && gqlQuery?.singleQuery?.query) {
              const missingValues = defaultValues.filter(value => !matchingValues.some(match => match.value === value));

              missingValues.forEach(missingValue => {
                const derrivedGqlQuery = { ...gqlQuery };

                if (derrivedGqlQuery && gqlQuery.singleQuery) {
                  derrivedGqlQuery.query = gqlQuery.singleQuery.query;
                  derrivedGqlQuery.variables = { ...gqlQuery.singleQuery.variables } as Record<string, unknown>;

                  const keyToUpdate = Object.keys(gqlQuery.singleQuery.variables)[0];
                  if (keyToUpdate) {
                    derrivedGqlQuery.variables[keyToUpdate] = missingValue;
                  }

                  if (Object.values(derrivedGqlQuery.variables).every(value => value !== undefined)) {
                    this.jsonSchemaService
                      .singleItemGraphqlQuery({ gqlQuery: derrivedGqlQuery })
                      .pipe(
                        filter((data: AutocompleteOption) => !!data),
                        take(1),
                        tap(res => {
                          if (res) {
                            this.values.update(vals => [...vals, res]);
                          }
                        })
                      )
                      .subscribe();
                  }
                }
              });
            }
          });
      } else if (this.props?.['url']) {
        this.loadDataWithRest(this.props['url'] as string)
          .pipe(
            filter((data: AutocompleteOption[]) => !!data),
            take(1)
          )
          .subscribe((data: AutocompleteOption[]) => {
            const defaultValues = Array.isArray(this.formControl.value)
              ? this.formControl.value
              : [this.formControl.value];

            const matchingValues = data.filter(item => defaultValues.includes(item?.value));
            this.values.set(matchingValues);
            this.optionsData = data;
            this.updateFilteredOptions();
          });
      }
    } else {
      const gqlQuery = this.props?.['gqlQuery'] as GqlQueryConfig | undefined;
      if (gqlQuery && gqlQuery.query && gqlQuery.fields && gqlQuery.fields.label && gqlQuery.fields.value) {
        // Load initial data without a value
        this.loadDataWithGql()
          .pipe(take(1))
          .subscribe((data: AutocompleteOption[]) => {
            this.optionsData = data;
            this.updateFilteredOptions();
          });
      } else if (this.props?.['url']) {
        this.loadDataWithRest(this.props['url'] as string)
          .pipe(
            filter((data: AutocompleteOption[]) => !!data),
            take(1)
          )
          .subscribe((data: AutocompleteOption[]) => {
            this.optionsData = data;
            this.updateFilteredOptions();
          });
      }
    }
  }

  setupFilteringOnInput(): void {
    // Listen for direct value changes (real-time)
    this.filterControl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(value => {
      // Update the custom value immediately to ensure it's current
      this.customValue = value || '';
      this.isFiltering = true;
    });

    // Handle input blur event to ensure last character is processed
    this.filterControl.valueChanges
      .pipe(
        takeUntil(this.$destroy),
        // Apply conditional debounce based on input length
        switchMap((value: string | null) => {
          const inputValue = value || '';

          // Apply the debounce only if the input meets minimum length
          // This helps with the "last character not filtering" issue
          if (inputValue.length >= this.minLengthForDebounce) {
            return of(inputValue).pipe(debounceTime(this.debounceTimeMs), distinctUntilChanged());
          } else {
            return of(inputValue).pipe(distinctUntilChanged());
          }
        }),
        switchMap((value: string) => {
          const gqlQuery = this.props?.['gqlQuery'] as GqlQueryConfig | undefined;
          if (gqlQuery && gqlQuery.query && gqlQuery.fields && gqlQuery.fields.label && gqlQuery.fields.value) {
            return this.filterGraphqlData(value);
          } else if (this.props?.['url']) {
            return this.filterRestData(value);
          }

          // If no external data source, filter the existing data
          return of(this.optionsData || []).pipe(
            map(data =>
              data.filter(item => {
                const label = item?.label?.toLowerCase() || '';
                const searchValue = value?.toLowerCase() || '';
                return label.includes(searchValue);
              })
            )
          );
        })
      )
      .subscribe(data => {
        this.optionsData = data;
        this.isFiltering = false;
        this.updateFilteredOptions();
      });
  }

  // Update filtered options with custom value if needed
  updateFilteredOptions(): void {
    this.filteredOptionsWithCustom = [...this.optionsData];

    // Add custom value option if needed
    if (this.allowCustomValues && this.customValue && !this.valueExistsInOptions(this.customValue)) {
      const customOption: AutocompleteOption = {
        label: this.customValue,
        value: this.customValue,
        isCustomValue: true,
      };

      // Put custom option at the top
      this.filteredOptionsWithCustom = [customOption, ...this.filteredOptionsWithCustom];
    }

    this.filter = of(this.filteredOptionsWithCustom);
    this.cdr.detectChanges();
  }

  // Handle blur event to ensure the last character is processed
  onInputBlur(): void {
    const currentValue = this.filterControl.value;
    if (currentValue) {
      this.processFilterValue(currentValue);
    }
  }

  // Process the filter value and update the filtered data
  processFilterValue(value: string): void {
    const gqlQuery = this.props?.['gqlQuery'] as GqlQueryConfig | undefined;
    let dataObservable: Observable<AutocompleteOption[]>;

    if (gqlQuery && gqlQuery.query && gqlQuery.fields && gqlQuery.fields.label && gqlQuery.fields.value) {
      dataObservable = this.filterGraphqlData(value);
    } else if (this.props?.['url']) {
      dataObservable = this.filterRestData(value);
    } else {
      dataObservable = of(this.optionsData || []).pipe(
        map(data =>
          data.filter(item => {
            const label = item?.label?.toLowerCase() || '';
            const searchValue = value?.toLowerCase() || '';
            return label.includes(searchValue);
          })
        )
      );
    }

    dataObservable.pipe(take(1)).subscribe(data => {
      this.optionsData = data;
      this.isFiltering = false;
      this.updateFilteredOptions();
    });
  }

  filterGraphqlData(filterValue: string): Observable<AutocompleteOption[]> {
    const gqlQuery = this.props?.['gqlQuery'] as GqlQueryConfig | undefined;
    if (!gqlQuery) {
      return of([]);
    }

    const derrivedGqlQuery = { ...gqlQuery };

    if (derrivedGqlQuery.searchable && typeof filterValue === 'string') {
      if (derrivedGqlQuery.queryInput) {
        const variables = derrivedGqlQuery.variables[derrivedGqlQuery.queryInput] as Record<string, unknown>;
        if (variables) {
          derrivedGqlQuery.variables[derrivedGqlQuery.queryInput] = {
            ...variables,
            searchKey: filterValue,
          };
        }
      } else if (derrivedGqlQuery.variables) {
        derrivedGqlQuery.variables = { ...derrivedGqlQuery.variables, searchKey: filterValue };
      }

      return this.jsonSchemaService.graphqlQuery({ gqlQuery: derrivedGqlQuery }).pipe(
        take(1),
        tap(result => {
          this.optionsData = result;
          this.cdr.detectChanges();
        })
      );
    }

    return this.jsonSchemaService.graphqlQuery({ gqlQuery: derrivedGqlQuery }).pipe(
      take(1),
      filter((data: AutocompleteOption[]) => !!data),
      map((data: AutocompleteOption[]) => {
        return data.filter((item: AutocompleteOption) => {
          const label = item?.label?.toLowerCase() || '';
          const searchValue = typeof filterValue === 'string' ? filterValue?.toLowerCase() : '';
          return label.includes(searchValue);
        });
      })
    );
  }

  filterRestData(filterValue: string): Observable<AutocompleteOption[]> {
    const url = (this.props?.['url'] as string) || '';
    return this.loadDataWithRest(url).pipe(
      filter((data: AutocompleteOption[]) => !!data),
      map((data: AutocompleteOption[]) => {
        return data.filter((item: AutocompleteOption) => {
          const label = item?.label?.toLowerCase() || '';
          const searchValue = typeof filterValue === 'string' ? filterValue?.toLowerCase() : '';
          return label.includes(searchValue);
        });
      })
    );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.allowCustomValues) {
      const customOption: AutocompleteOption = {
        label: value,
        value: value,
        isCustomValue: true,
      };

      this.values.update(values => [...values, customOption]);

      // Clear the input value
      event.chipInput!.clear();
      this.filterControl.setValue('');
      this.customValue = '';
      this.updateFilteredOptions();

      this.updateFormControlValue();
    }
  }

  remove(value: AutocompleteOption): void {
    this.values.update(vals => {
      const index = vals.findIndex(v => v.value === value.value);
      if (index < 0) {
        return vals;
      }
      vals.splice(index, 1);
      this.announcer.announce(`Removed ${value.label}`);
      return [...vals];
    });

    this.updateFormControlValue();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = event.option.value as AutocompleteOption;

    // If it's a custom value option, create a proper chip value
    if (selectedOption.isCustomValue) {
      const customOption: AutocompleteOption = {
        label: selectedOption.value.toString(), // Use the value (without "Add")
        value: selectedOption.value,
        isCustomValue: true,
      };

      this.values.update(vals => [...vals, customOption]);
    } else {
      this.values.update(vals => [...vals, selectedOption]);
    }

    event.option.deselect();
    this.filterControl.setValue('');
    this.customValue = '';
    this.updateFilteredOptions();
    this.updateFormControlValue();
  }

  updateFormControlValue(): void {
    const currentValues = this.values();
    const valuePropertyName = this.props?.['valuePropertyName'] as string | undefined;

    if (valuePropertyName) {
      this.formControl.setValue(currentValues.map(item => item[valuePropertyName]));
    } else {
      this.formControl.setValue(currentValues.map(item => item.value));
    }
  }

  loadDataWithRest(url: string): Observable<AutocompleteOption[]> {
    if (url) {
      return this.jsonSchemaService.loadValues(url);
    } else {
      return of((this.props?.['options'] as AutocompleteOption[]) || []);
    }
  }

  loadDataWithGql(): Observable<AutocompleteOption[]> {
    return this.jsonSchemaService.graphqlQuery(this.props as unknown);
  }

  valueExistsInOptions(value: string): boolean {
    if (!value || !this.optionsData) return true;
    return this.optionsData.some(option => option.label?.toLowerCase() === value.toLowerCase());
  }

  displayFn(value: AutocompleteOption | string): string {
    if (typeof value === 'string') {
      return value;
    }
    return value?.label || '';
  }

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject((this.props?.['styles'] as string) || '');
  }

  getStylesClasses(id: string): string {
    const styleClasses = this.props?.['styleClasses'] as Record<string, string> | undefined;
    if (!styleClasses || !styleClasses[id]) {
      return '';
    }
    return styleClasses[id];
  }

  override ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
