<mat-form-field [className]="props.style" [appearance]="props.appearance">
  <mat-label id="label" [ngClass]="getStylesClasses('label')"
    >{{ props?.label }} {{ props.required ? '*' : '' }}</mat-label
  >
  <input
    matInput
    type="text"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [ngModel]="formControl.value"
    hmtDefaultDecimalPlacesCommaDirective />
  <mat-error *ngIf="formControl.errors?.required">{{ field.validation?.messages?.required }}</mat-error>
</mat-form-field>
