import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';

@Component({
  selector: 'app-panel-wrapper-with-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './panel-wrapper-with-header.component.html',
  styleUrl: './panel-wrapper-with-header.component.scss',
})
export class PanelWrapperWithHeaderComponent extends FieldWrapper {
  constructor(private readonly jsonSchemaService: JsonSchemaService) {
    super();
  }

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject(this.props['styles'] || '');
  }

  getStylesClasses(id: string) {
    if (!this.props['styleClasses'] || !this.props['styleClasses'][id]) {
      return '';
    }
    return this.props['styleClasses'][id];
  }
}
