<div class="card" [ngStyle]="styleObject">
  <div class="card-header" *ngIf="!props['noHeader']">
    <div class="close-button" *ngIf="props['closeBtn']">
      <mat-icon>close</mat-icon>
    </div>
    <div class="close-button" *ngIf="props['exitBtn'] === 'right'">
      <mat-icon>close</mat-icon>
    </div>
    <div class="header-text">
      {{ props.label }}
    </div>
  </div>
</div>
