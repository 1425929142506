import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

//this is marker component for hidden fields
@Component({
  selector: 'hmt-hidden-type',
  standalone: true,
  imports: [],
  template: ``,
  styles: ``,
})
export class HiddenTypeComponent extends FieldType {}
