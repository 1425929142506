<mat-form-field [appearance]="props.appearance">
  <mat-label id="label" [ngClass]="getStylesClasses('label')">{{ props?.label }}</mat-label>
  <input
    id="auto-chip"
    [ngClass]="getStylesClasses('input')"
    [formControl]="formControl"
    [disabled]="props.disabled"
    [placeholder]="props.placeholder"
    #chipInput
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let value of filter | async" [value]="value">
      {{ value.label }}
    </mat-option>
  </mat-autocomplete>
  <div class="spacer"></div>
  <mat-chip-grid #chipGrid>
    @for (value of values(); track $index) {
      <mat-chip (removed)="remove(value)" [ngStyle]="styleObject" id="chip" [ngClass]="getStylesClasses('chip')">
        {{ value?.label }}
        <button matChipRemove id="chipRemove" [ngClass]="getStylesClasses('chipRemove')">
          <mat-icon [ngStyle]="styleObject" id="chipRemoveIcon" [ngClass]="getStylesClasses('chipRemoveIcon')"
            >cancel</mat-icon
          >
        </button>
      </mat-chip>
    }
  </mat-chip-grid>
  <mat-error *ngIf="formControl.errors?.required">Required</mat-error>
</mat-form-field>
