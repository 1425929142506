<div class="card" [ngStyle]="styleObject">
  <div class="card-header" [ngClass]="getStylesClasses('header')">
    <div class="header-text">
      {{ props['title'] }}
    </div>
  </div>
  <div class="card-body" [ngClass]="getStylesClasses('scrollable-body')">
    <ng-container #fieldComponent></ng-container>
  </div>
</div>
