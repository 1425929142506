<div [ngSwitch]="props.type">
  <button
    *ngSwitchCase="'raised-button'"
    mat-raised-button
    [type]="props?.buttonType || 'button'"
    [disabled]="props?.disabled"
    [ngStyle]="styleObject"
    [ngClass]="getStylesClasses('button')"
    [color]="props?.color"
    (click)="onClick($event)">
    {{ props?.title }}
  </button>
  <button
    *ngSwitchCase="'flat-button'"
    mat-flat-button
    [type]="props?.buttonType || 'button'"
    [disabled]="props?.disabled"
    [ngStyle]="styleObject"
    [color]="props?.color"
    [ngClass]="getStylesClasses('button')"
    (click)="onClick($event)">
    {{ props?.title }}
  </button>
  <button
    *ngSwitchCase="'stroked-button'"
    mat-stroked-button
    [type]="props?.buttonType || 'button'"
    [disabled]="props?.disabled"
    [ngStyle]="styleObject"
    (click)="onClick($event)"
    [color]="props?.color"
    [ngClass]="getStylesClasses('button')">
    {{ props?.title }}
  </button>
  <button
    *ngSwitchCase="'icon-button'"
    mat-icon-button
    [type]="props?.buttonType || 'button'"
    [disabled]="props?.disabled"
    [ngStyle]="styleObject"
    (click)="onClick($event)"
    [color]="props?.color"
    [ngClass]="getStylesClasses('button')">
    <mat-icon>{{ props?.icon }}</mat-icon>
  </button>
  <button
    *ngSwitchCase="'fab'"
    mat-fab
    [type]="props?.buttonType || 'button'"
    [disabled]="props?.disabled"
    [ngStyle]="styleObject"
    (click)="onClick($event)"
    [color]="props?.color"
    [ngClass]="getStylesClasses('button')">
    {{ props?.title }}
  </button>
  <button
    *ngSwitchCase="'mini-fab'"
    mat-mini-fab
    [type]="props?.buttonType || 'button'"
    [disabled]="props?.disabled"
    [ngClass]="getStylesClasses('button')"
    (click)="onClick($event)"
    [color]="props?.color"
    [ngStyle]="styleObject">
    {{ props?.title }}
  </button>
  <button
    *ngSwitchDefault
    mat-button
    [type]="props?.buttonType || 'button'"
    [disabled]="props?.disabled"
    [ngClass]="getStylesClasses('button')"
    [ngStyle]="styleObject"
    [color]="props?.color"
    (click)="onClick($event)">
    {{ props?.title }}
  </button>
</div>
