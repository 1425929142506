import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormlyModule } from '@ngx-formly/core';
import { FieldType, FormlyMaterialModule } from '@ngx-formly/material';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { JsonSchemaService } from '../../../../json-schema-forms/services/json-schema.service';

@Component({
  selector: 'formly-autocomplete-type',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatInputModule,
    FormlyMaterialModule,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
  ],
  templateUrl: './autocomplete-type.component.html',
  styleUrl: './autocomplete-type.component.scss',
  providers: [JsonSchemaService],
})
export class AutocompleteTypeComponent extends FieldType<any> implements OnInit, OnDestroy {
  filter: any[];
  private $destroy: Subject<void> = new Subject<void>();
  customValue: string = '';

  constructor(
    private jsonSchemaService: JsonSchemaService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.initialDataLoad();
    this.listenToValueChanges();
  }

  listenToValueChanges() {
    this.formControl.valueChanges
      .pipe(
        startWith(''),
        takeUntil(this.$destroy),
        debounceTime(300),
        switchMap((value: string) => {
          if (typeof value === 'string') {
            this.customValue = value;
          }

          if (
            (this.props?.gqlQuery &&
              this.props?.gqlQuery?.query &&
              this.props?.gqlQuery?.fields &&
              this.props?.gqlQuery?.fields?.label &&
              this.props?.gqlQuery?.fields?.value &&
              typeof value === 'string') ||
            typeof value === 'undefined'
          ) {
            return this.filterGraphqlData(value);
          } else if (this.props?.url) {
            return this.filterRestData(value);
          }
          return of([]);
        })
      )
      .subscribe(data => {
        if (this.props?.allowCustomValues) {
          if (this.customValue && !this.valueExistsInOptions(this.customValue)) {
            this.addCustomValueToOptions();
          }
        }
      });
  }

  initialDataLoad(): void {
    if (
      this.props?.gqlQuery &&
      this.props?.gqlQuery?.query &&
      this.props?.gqlQuery?.fields &&
      this.props?.gqlQuery?.fields?.label &&
      this.props?.gqlQuery?.fields?.value
    ) {
      if (this.props.gqlQuery.searchable) {
        if (this.props.gqlQuery?.queryInput) {
          this.props.gqlQuery.variables[this.props.gqlQuery.queryInput].searchKey = '';
        } else {
          this.props.gqlQuery.variables.searchKey = '';
        }
      }
      this.loadDataWithGql(this.props)
        .pipe(
          take(1),
          tap(result => {
            const savedValue = result.find(item => item.value === this.field.formControl.value);
            const derrivedProps = {
              gqlQuery: { ...this.props.gqlQuery },
              variables: { ...this.props.gqlQuery.variables },
            };
            if (savedValue) {
              this.field.formControl.setValue(savedValue);
            } else {
              if (this.props.gqlQuery.singleQuery?.query) {
                derrivedProps.gqlQuery.query = this.props.gqlQuery.singleQuery.query;
                derrivedProps.gqlQuery.variables = {
                  ...this.props.gqlQuery.singleQuery.variables,
                  [Object.keys(this.props.gqlQuery.singleQuery.variables)[0]]: this.field.formControl.value,
                };

                Object.entries(derrivedProps?.gqlQuery?.variables).forEach(([key, value]) => {
                  if (value === undefined) {
                    return;
                  }
                });

                if (Object.values(derrivedProps?.gqlQuery?.variables).every(value => value !== undefined)) {
                  this.jsonSchemaService
                    .singleItemGraphqlQuery(derrivedProps)
                    .pipe(
                      filter((data: any) => data),
                      take(1),
                      tap(res => {
                        this.field.formControl.setValue(res);
                      })
                    )
                    .subscribe();
                }
              }
            }
          })
        )
        .subscribe();
    } else if (this.props?.url) {
      this.loadDataWithRest(this.props.url)
        .pipe(filter((data: any) => data))
        .subscribe();
    }
  }

  filterGraphqlData(filterValue): Observable<any> {
    const derrivedProps = {
      gqlQuery: { ...this.props.gqlQuery },
      variables: this.props.gqlQuery.variables,
    };
    if (this.props.gqlQuery.searchable) {
      if (typeof filterValue === 'string') {
        if (this.props.gqlQuery?.queryInput) {
          derrivedProps.gqlQuery.variables[this.props.gqlQuery.queryInput] = {
            ...derrivedProps.gqlQuery.variables[this.props.gqlQuery.queryInput],
            searchKey: filterValue,
          };
        } else {
          derrivedProps.gqlQuery.variables = { ...derrivedProps.gqlQuery.variables, searchKey: filterValue };
        }
      }
      return this.loadDataWithGql(derrivedProps).pipe(
        take(1),
        tap(result => {
          this.filter = result;
          this.cdr.detectChanges();
        })
      );
    }

    return this.loadDataWithGql(derrivedProps).pipe(
      take(1),
      filter((data: any) => {
        data = this.filter = data.filter((item: any) => {
          const label = item?.label?.toLowerCase();
          const searchValue = typeof filterValue === 'string' ? filterValue?.toLowerCase() : '';
          return label.includes(searchValue);
        });
        return data;
      })
    );
  }

  filterRestData(filterValue) {
    return this.loadDataWithRest(this.props.url).pipe(
      filter((data: any) => data),
      filter((data: any) => {
        this.filter = data.filter((item: any) => item?.label?.toLowerCase().includes(filterValue?.toLowerCase()));
        return data;
      })
    );
  }

  displayFn(value) {
    return value?.label || value;
  }

  loadDataWithRest(url: string): Observable<any> {
    if (url) {
      return this.jsonSchemaService.loadValues(url);
    } else {
      return of(this.props.options);
    }
  }

  loadDataWithGql(props: any): Observable<any> {
    return this.jsonSchemaService.graphqlQuery(props);
  }

  getStylesClasses(id: string) {
    if (!this.props['styleClasses'] || !this.props['styleClasses'][id]) {
      return '';
    }
    return this.props['styleClasses'][id];
  }

  override ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  valueExistsInOptions(value: string): boolean {
    if (!value) return true;
    return this.filter.some(option => option.label?.toLowerCase() === value.toLowerCase());
  }

  addCustomValueToOptions(): void {
    if (!this.customValue) return;

    const customOptionExists = this.filter.some(
      option => option.isCustomValue && option.label.toLowerCase() === this.customValue.toLowerCase()
    );

    if (!customOptionExists) {
      const customOption = {
        label: this.customValue,
        value: this.customValue,
        isCustomValue: true,
      };

      this.filter = [customOption, ...this.filter];
      this.cdr.detectChanges();
    }
  }

  createCustomValue(): void {
    if (!this.customValue) return;

    const customValue = {
      label: this.customValue,
      value: this.customValue,
      isCustomValue: true,
    };

    this.field.formControl.setValue(customValue);
    this.customValue = '';
  }
}
