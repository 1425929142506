import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatNativeDateModule } from '@angular/material/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { InMemoryCache } from '@apollo/client/core';
import { AuthGuardService } from '@core/services/auth/auth-guard.service';
import { environment } from '@env/environment';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { HttpLoaderFactory, formlyValidationConfig } from './app.module';
import { routes } from './app.routes';
import { COMPONENT_CONFIG } from './json-schema-forms/dynamic-component-map';

const token = localStorage.getItem('token');

const createApollo = (httpLink: HttpLink) => ({
  link: httpLink.create({ uri: environment.haulmatic.apiGateway }),

  cache: new InMemoryCache({
    addTypename: false,
    resultCaching: false,
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    mutate: {
      fetchPolicy: 'network-only',
    },
  },
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    AuthGuardService,
    AngularFirestore,
    provideAnimationsAsync(),
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    Apollo,
    provideAnimationsAsync(),
    importProvidersFrom([
      HttpClientModule,
      //TODO: dynamically import the modules
      FormlyModule.forRoot(COMPONENT_CONFIG),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      MatNativeDateModule,
    ]),
    { provide: FORMLY_CONFIG, multi: true, useFactory: formlyValidationConfig, deps: [TranslateService] },
  ],
};
