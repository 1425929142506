import { Component, OnDestroy, OnInit } from '@angular/core';
import { SetCurrentTransactionId } from '@core/store/job-updates/job-updates.actions';
import { SharedState } from '@core/store/shared/shared.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'hmt-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'WebApp';

  @Select(SharedState.getSpinnerStatus) isSpinnerEnabled: Observable<{ spinner: boolean; spinnerMessage: string }>;
  private unsubscribe: Subject<void> = new Subject();

  spinnerPros: { spinner: boolean; spinnerMessage: string } = { spinner: false, spinnerMessage: '' };

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new SetCurrentTransactionId());
    this.isSpinnerEnabled
      .pipe(
        tap(result => (this.spinnerPros = result)),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      try {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = name;
        document.getElementsByTagName('head')[0].appendChild(script);
        console.log('Script Loaded');
        resolve('Ok');
      } catch (e) {
        reject(`Error ${e}`);
      }
    });
  }
}
