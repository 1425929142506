import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-frequent-searches',
  templateUrl: './frequent-searches.component.html',
  styleUrl: './frequent-searches.component.scss',
})
export class FrequentSearchesComponent extends FieldWrapper {
  items = Array(10).fill({ name: 'name', value: 'value' });
}
