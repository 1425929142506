import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

@Component({
  selector: 'app-row-wrapper',
  standalone: true,
  imports: [CommonModule, FormlyMaterialModule, FormlyModule],
  templateUrl: './row-wrapper.component.html',
  styleUrl: './row-wrapper.component.scss',
})
export class RowWrapperComponent extends FieldWrapper {}
