import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FieldType } from '@ngx-formly/material';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';

@Component({
  selector: 'app-button-type',
  standalone: true,
  imports: [MatButtonModule, CommonModule, MatIconModule],
  templateUrl: './button-type.component.html',
  styleUrl: './button-type.component.scss',
})
export class ButtonTypeComponent extends FieldType<any> {
  constructor(private readonly jsonSchemaService: JsonSchemaService) {
    super();
  }

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject(this.props['styles'] || '');
  }

  onClick($event: Event) {
    if (this.props.onClick) {
      this.props.onClick($event);
    }
  }

  getStylesClasses(id: string) {
    if (this.props.disabled) {
      return 'disabled';
    }

    if (!this.props['styleClasses'] || !this.props['styleClasses'][id]) {
      return '';
    }
    return this.props['styleClasses'][id];
  }
}
