import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule, FieldArrayType } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

@Component({
  selector: 'app-array-type',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormlyMaterialModule, FormlyModule, HttpClientModule],
  templateUrl: './array-type.component.html',
  styleUrl: './array-type.component.scss'
})
export class ArrayTypeComponent extends FieldArrayType {

}
