<div class="document-upload">
  <div class="document-upload-header">
    <div>{{ componentName }}</div>
  </div>
  <div class="document-upload-body">
    <div *ngFor="let category of categories | keyvalue">
      <hmt-document-upload-category
        [viewOnly]="props?.disabled"
        [categoryName]="category.key"
        [files]="category.value"
        (fileUploaded)="uploadFile($event)"></hmt-document-upload-category>
    </div>
  </div>
</div>
