import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FieldType } from '@ngx-formly/core';
import { Observable, filter, of, startWith, switchMap } from 'rxjs';
import { JsonSchemaService } from '../../../services/json-schema.service';

@Component({
  selector: 'hmt-chip-type',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './chip-type.component.html',
  styleUrl: './chip-type.component.scss',
})
export class ChipTypeComponent extends FieldType<any> implements OnInit {
  @Input() items: { name: string; value: string }[] = [];
  @Input() staticComponent = false;

  constructor(private jsonSchemaService: JsonSchemaService) {
    super();
  }

  ngOnInit(): void {
    this.items = this.staticComponent ? this.items : this.props.items || [];
    this.formControl.valueChanges
      .pipe(
        startWith(''),
        switchMap((value: string) => {
          if (this.staticComponent) {
            this.items = this.items.filter(i => i.name.includes(value));
            return this.items;
          } else {
            return this.loadData(this.props.url).pipe(filter((data: any) => data));
          }
        })
      )
      .subscribe();
    this.formControl.setValue(this.items);
  }

  loadData(url: string): Observable<any> {
    if (url) {
      return this.jsonSchemaService.loadValues(url);
    }
    return of([]);
  }

  remove(item: { name: string; value: string }) {
    this.items = this.items.filter(i => i.name !== item.name);
    this.formControl.setValue(this.items);
  }

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject(this.props['styles'] || '');
  }
}
