<div class="object-container" *ngIf="!props?.hidden" [ngStyle]="styleObject" [ngClass]="getStylesClasses('container')">
  <p *ngIf="props.description" [ngClass]="getStylesClasses('description')">{{ props.description }}</p>
  <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
    <formly-validation-message [field]="field"></formly-validation-message>
  </div>
  <formly-field
    [ngClass]="getStylesClasses('field')"
    *ngFor="let field of field.fieldGroup"
    [field]="field"></formly-field>
</div>
