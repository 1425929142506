import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Select, Store } from '@ngxs/store';
import { DocumentUploadCategoryComponent } from '@shared/components/document-upload-category/document-upload-category.component';
import { EntityMap } from '@shared/types';
import { WizardState } from 'app/modules/wizard/store/wizard.state';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UploadedFileType } from '../../../modules/wizard/models/uploaded-file.type';
import { UploadOrderDocument } from '../../../modules/wizard/store/wizard.actions';

@Component({
  selector: 'hmt-order-document-upload',
  templateUrl: './order-document-upload.component.html',
  styleUrl: './order-document-upload.component.scss',
  standalone: true,
  imports: [DocumentUploadCategoryComponent, CommonModule],
})
export class OrderDocumentUploadComponent extends FieldType implements OnInit, OnDestroy {
  @Select(WizardState.getOrderDocuments) orderDocuments$: Observable<EntityMap<string, UploadedFileType[]>>;
  @Input() categories: { [category: string]: UploadedFileType[] } = {};
  @Input() componentName: string = 'Document Upload';
  destroy$ = new Subject<void>();

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.categories = this.props['categories'] || {};
    this.orderDocuments$
      .pipe(takeUntil(this.destroy$))
      .subscribe((documents: EntityMap<string, UploadedFileType[]>) => {
        this.categories = { ...this.categories, ...documents };
      });
  }

  uploadFile(event$: { category: string; file: UploadedFileType }) {
    this.store.dispatch(new UploadOrderDocument(event$.category, event$.file));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
