import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FormlyModule } from '@ngx-formly/core';
import { FieldType, FormlyMaterialModule } from '@ngx-formly/material';

@Component({
  selector: 'app-datetime-picker-type',
  standalone: true,
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatAutocompleteModule,
    MatInputModule,
    FormlyMaterialModule,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
  ],
  templateUrl: './datetime-picker-type.component.html',
  styleUrl: './datetime-picker-type.component.scss',
})
export class DatetimePickerTypeComponent extends FieldType<any> implements OnInit {
  ngOnInit(): void {}
}
