import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormlyModule } from '@ngx-formly/core';
import { FieldType, FormlyMaterialModule } from '@ngx-formly/material';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';
import { Observable, Subject, filter, of, startWith, switchMap, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-autocomplete-chip',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatInputModule,
    FormlyMaterialModule,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatChipsModule,
    FormsModule,
  ],
  templateUrl: './autocomplete-chip.component.html',
  styleUrl: './autocomplete-chip.component.scss',
})
export class AutocompleteChipComponent extends FieldType<any> implements OnInit, OnDestroy {
  private $destroy = new Subject<void>();
  filter: Observable<any[]> | undefined;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly values = signal([]);
  readonly announcer = inject(LiveAnnouncer);

  constructor(private jsonSchemaService: JsonSchemaService) {
    super();
  }

  ngOnInit(): void {
    this.values.set([]);
    if (this.formControl?.value) {
      //edit Mode
      this.loadDataWithGql(this.props)
        .pipe(take(1))
        .subscribe((data: any) => {
          const defaultValues = Array.isArray(this.formControl.value)
            ? this.formControl.value
            : [this.formControl.value];

          const matchingValues = data.filter(item => defaultValues.includes(item?.value));

          this.values.set(matchingValues);
        });
    }

    this.formControl.valueChanges
      .pipe(
        startWith(''),
        takeUntil(this.$destroy),
        switchMap((value: string) => {
          if (
            this.props?.gqlQuery &&
            this.props?.gqlQuery?.query &&
            this.props?.gqlQuery?.fields &&
            this.props?.gqlQuery?.fields?.label &&
            this.props?.gqlQuery?.fields?.value
          ) {
            return this.loadDataWithGql(this.props).pipe(
              filter((data: any) => {
                data = this.filter = of(
                  data.filter((item: any) => {
                    const label = item?.label?.toLowerCase();
                    const searchValue = value?.toLowerCase();
                    return label.includes(searchValue);
                  })
                );
                return data;
              })
            );
          } else if (this.props?.url) {
            return this.loadDataWithRest(this.props.url).pipe(
              filter((data: any) => data),
              filter((data: any) => {
                this.filter = of(data.filter((item: any) => item?.label?.toLowerCase().includes(value?.toLowerCase())));
                return data;
              })
            );
          }
          return of([]);
        })
      )
      .subscribe();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.values.update(values => [...values, value]);
      this.formControl.setValue(this.values());
    }
  }

  remove(value: string): void {
    this.values.update(vals => {
      const index = vals.indexOf(value);
      if (index < 0) {
        return vals;
      }
      vals.splice(index, 1);
      this.announcer.announce(`Removed ${value}`);
      return [...vals];
    });
    this.formControl.setValue(this.values());
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.values.update(vals => [...vals, event.option.value]);
    event.option.deselect();
    this.formControl.setValue(this.values());
  }

  loadDataWithRest(url: string): Observable<any> {
    if (url) {
      return this.jsonSchemaService.loadValues(url);
    } else {
      return of(this.props.options);
    }
  }

  loadDataWithGql(props: any): Observable<any> {
    return this.jsonSchemaService.graphqlQuery(props);
  }

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject(this.props['styles'] || '');
  }

  getStylesClasses(id: string) {
    if (!this.props['styleClasses'] || !this.props['styleClasses'][id]) {
      return '';
    }
    return this.props['styleClasses'][id];
  }

  override ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
