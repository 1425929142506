import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';

@Component({
  selector: 'formly-wrapper-panel',
  standalone: true,
  imports: [CommonModule, FormlyMaterialModule, FormlyModule, MatIconModule],
  templateUrl: './panel-wrapper.component.html',
  styleUrl: './panel-wrapper.component.scss',
})
export class PanelWrapperComponent extends FieldWrapper {
  constructor(private readonly jsonSchemaService: JsonSchemaService) {
    super();
  }

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject(this.props['styles'] || '');
  }

  getStylesClasses(id: string) {
    if (!this.props['styleClasses'] || !this.props['styleClasses'][id]) {
      return '';
    }

    const classes = this.props['styleClasses'][id].split(';');

    return classes;
  }

  closeDialog() {}
}
