import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormlyModule } from '@ngx-formly/core';
import { FieldType, FormlyMaterialModule } from '@ngx-formly/material';
import { DefaultDecimalPlacesCommaDirective } from '@shared/directives/default-decimal-places-comma.directive';

@Component({
  selector: 'hmt-decimal-thousand-number-type',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatInputModule,
    FormlyMaterialModule,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    DefaultDecimalPlacesCommaDirective,
  ],
  providers: [],
  templateUrl: './decimal-thousand-number-type.component.html',
  styleUrl: './decimal-thousand-number-type.component.scss',
})
export class DecimalThousandNumberTypeComponent extends FieldType<any> {
  constructor() {
    super();
  }

  getStylesClasses(id: string) {
    if (!this.props['styleClasses'] || !this.props['styleClasses'][id]) {
      return '';
    }
    return this.props['styleClasses'][id];
  }
}
