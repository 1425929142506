import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { FieldWrapper, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

@Component({
  selector: 'app-tabs-type',
  standalone: true,
  imports: [CommonModule, MatTabsModule, FormlyMaterialModule, FormlyModule],
  templateUrl: './tabs-type.component.html',
  styleUrl: './tabs-type.component.scss',
})
export class TabsTypeComponent extends FieldWrapper {
  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field.formControl.valid;
    }
    return field.fieldGroup ? field.fieldGroup.every(f => this.isValid(f)) : true;
  }
}
