import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@core/services/auth/auth-guard.service';
import { AppWrapperComponent } from './app-wrapper/app-wrapper.component';
import { FormsGeneratorComponent } from './json-schema-forms/pages/forms-generator/forms-generator.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuardService],
    data: { routeId: 'wizard' },
  },
  {
    path: '',
    component: AppWrapperComponent,
    loadChildren: () => import('./app-wrapper/app-wrapper.module').then(m => m.AppWrapperModule),
  },
  {
    path: 'jsonSchema',
    component: FormsGeneratorComponent,
    loadChildren: () => import('./json-schema-forms/json-schema-forms.module').then(m => m.JsonSchemaFormsModule),
  },
  {
    path: 'procurement',
    component: FormsGeneratorComponent,
    loadChildren: () =>
      import('./modules/procurement/procurement-routing.module').then(m => m.ProcurementRoutingModule),
  },

  // TODO: this wizard route used app wrapper

  // {
  //   path: 'wizard',
  //   component: JobfileComponent,
  //   //TODO: rename the module to wizard
  //   loadChildren: () => import('./modules/jobfile/jobfile-routing.module').then(m => m.WizardRoutingModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
