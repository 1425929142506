import { Component, OnInit, inject } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { JobFile } from 'app/modules/wizard/models/job-file.model';
import { CurrentTenderState } from 'app/modules/wizard/store/current-tender/current-tender.state';
import { WizardState } from 'app/modules/wizard/store/wizard.state';
import { Observable, filter, take, tap } from 'rxjs';
import { TenderModel } from '../../models/tender.model';
import { TenderService } from '../../services/tender-service';

@Component({
  selector: 'hmt-selected-payment-term',
  standalone: true,
  imports: [],
  templateUrl: './selected-payment-term.component.html',
  styleUrl: './selected-payment-term.component.scss',
})
export class SelectedPaymentTermComponent extends FieldWrapper implements OnInit {
  store$ = inject(Store);
  paymentTerm: string = '';

  currentTender$: Observable<TenderModel> = this.store$.select(CurrentTenderState.getTender);
  getJobFile$: Observable<JobFile> = this.store$.select(WizardState.getJobFile);

  constructor(private tenderService: TenderService) {
    super();
  }

  ngOnInit(): void {
    this.getJobFile$
      .pipe(
        filter(jobFile => !!jobFile),
        take(1),
        tap(jobFile => {
          if (jobFile.tenderId) {
            this.tenderService.loadTenderById(jobFile.tenderId).subscribe();
          }
        })
      )
      .subscribe();
    this.currentTender$.subscribe(tender => {
      if (tender) {
        this.paymentTerm = tender.paymentTerm.data.paymentOption.text ?? '';
      }
    });
  }
}
