import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';

@Component({
  selector: 'app-full-screen-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './full-screen-wrapper.component.html',
  styleUrl: './full-screen-wrapper.component.scss',
})
export class FullScreenWrapperComponent extends FieldWrapper {
  constructor(private readonly jsonSchemaService: JsonSchemaService) {
    super();
  }

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject(this.props['styles'] || '');
  }
}
