import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';
import { filter, Observable, of, Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'hmt-select-type',
  standalone: true,
  imports: [FormlyMaterialModule, ReactiveFormsModule, FormlyModule, CommonModule, MatFormFieldModule, MatSelectModule],
  templateUrl: './select-type.component.html',
  styleUrl: './select-type.component.scss',
})
export class SelectTypeComponent extends FieldType<any> implements OnInit, OnDestroy {
  private $destroy: Subject<void> = new Subject<void>();
  values: any[];

  constructor(private jsonSchemaService: JsonSchemaService) {
    super();
  }

  ngOnInit(): void {
    if (this.props && this.props.options) {
      this.props.options
        .pipe(
          takeUntil(this.$destroy),
          tap((values: any) => {
            this.values = values;
            this.field.formControl.setValue(this.values.find(value => value?.value === this.field.formControl?.value));
          })
        )
        .subscribe();
    } else {
      this.initialDataLoad();
    }
  }

  displayFn(value) {
    return value ? value?.label : undefined;
  }

  loadDataWithRest(url: string): Observable<any> {
    if (url) {
      return this.jsonSchemaService.loadValues(url);
    } else {
      return of(this.props.options);
    }
  }

  loadDataWithGql(props: any): Observable<any> {
    return this.jsonSchemaService.graphqlQuery(props);
  }

  initialDataLoad(): void {
    if (
      this.props?.gqlQuery &&
      this.props?.gqlQuery?.query &&
      this.props?.gqlQuery?.fields &&
      this.props?.gqlQuery?.fields?.label &&
      this.props?.gqlQuery?.fields?.value
    ) {
      this.loadDataWithGql(this.props)
        .pipe(
          take(1),
          tap(result => {
            this.values = result;
            this.field.formControl.setValue(
              this.values.find(keyValue => keyValue.value === this.field.formControl.value)
            );
          })
        )
        .subscribe();
    } else if (this.props?.url) {
      this.loadDataWithRest(this.props.url)
        .pipe(filter((data: any) => data))
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
