import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API } from '@configs/api-endpoints';
import { LoginFailed, Logout } from '@core/store/auth/auth.actions';
import { AuthState } from '@core/store/auth/auth.state';
import { SetSpinner } from '@core/store/shared/shared.actions';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private store: Store,
    public router: Router
  ) {}

  /**
   * @description - return token from storage or cookie
   */
  private getToken(): string {
    return this.store.selectSnapshot(AuthState.token);
  }

  /**
   * @description - intercept the http request and put jwt token
   */
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.getToken();

    let clonedRequest = req;
    if (!req.url.includes('storage.googleapis.com')) {
      clonedRequest = req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }
    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (req.url.includes(API.auth.getToken)) {
          this.store.dispatch(new LoginFailed(error.message));
          return throwError(error);
        }
        if (error.status === HttpStatusCode.Unauthorized) {
          this.store.dispatch(new Logout(true, this.router.url));
        }
        this.store.dispatch(new SetSpinner(false));
        return throwError(error);
      })
    );
  }
}
