<mat-form-field [appearance]="props?.['appearance'] || 'outline'">
  <mat-label id="label" [ngClass]="getStylesClasses('label')">
    {{ props?.['label'] }} {{ props?.['required'] ? '*' : '' }}
  </mat-label>
  <input
    id="auto-chip"
    [ngClass]="getStylesClasses('input')"
    [formControl]="filterControl"
    [disabled]="props?.['disabled']"
    [placeholder]="props?.['placeholder']"
    (blur)="onInputBlur()"
    #chipInput
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)" />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
    <mat-option *ngFor="let value of filter | async" [value]="value">
      {{ value.label }}
    </mat-option>
  </mat-autocomplete>

  <div class="spacer"></div>
  <mat-chip-grid #chipGrid>
    @for (value of values(); track $index) {
      <mat-chip (removed)="remove(value)" [ngStyle]="styleObject" id="chip" [ngClass]="getStylesClasses('chip')">
        {{ value?.label }}
        <button matChipRemove id="chipRemove" [ngClass]="getStylesClasses('chipRemove')">
          <mat-icon [ngStyle]="styleObject" id="chipRemoveIcon" [ngClass]="getStylesClasses('chipRemoveIcon')"
            >cancel</mat-icon
          >
        </button>
      </mat-chip>
    }
  </mat-chip-grid>
  <mat-error *ngIf="formControl.errors?.['required']">
    {{ field?.validation?.messages?.['required'] || 'Required' }}
  </mat-error>
</mat-form-field>
