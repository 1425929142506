import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';

@Component({
  selector: 'hmt-object-type',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormlyMaterialModule, FormlyModule, HttpClientModule],
  templateUrl: './object-type.component.html',
  styleUrl: './object-type.component.scss',
})
export class ObjectTypeComponent extends FieldType {
  constructor(private readonly jsonSchemaService: JsonSchemaService) {
    super();
  }

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject(this.props['styles'] || '');
  }

  getStylesClasses(id: string) {
    if (!this.props['styleClasses'] || !this.props['styleClasses'][id]) {
      return '';
    }
    return this.props['styleClasses'][id];
  }
}
